<template>
  <Layout style="background-color:white;">
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-md-12">
         <b-card
           style="max-width: 40rem;"
           class="mb-2"
         >
           <b-card-text>
            <b-form-group id="fieldset-1" label="E-mail" label-for="input-1">
              <b-form-input id="input-1" v-model="email" trim></b-form-input>
            </b-form-group>
            <b-form-group id="fieldset-1" label="Şifre" label-for="input-1">
              <b-form-input type="password" v-model="password" trim></b-form-input>
            </b-form-group>
            <b-form-group id="fieldset-1" label="Role" label-for="input-1">
              <b-form-select v-model="role" :options="options"></b-form-select>
            </b-form-group>
            <b-form-group id="fieldset-1" label="Dist" label-for="input-1">
               <b-form-select v-model="dist" :options="distoptions"></b-form-select>
            </b-form-group>
            <b-form-group id="fieldset-1" label="Adı" label-for="input-1">
              <b-form-input id="input-1" v-model="adi" trim></b-form-input>
            </b-form-group>
            <b-form-group id="fieldset-1" label="Soyadi" label-for="input-1">
              <b-form-input id="input-1" v-model="soyadi" trim></b-form-input>
            </b-form-group>
            <b-form-group id="fieldset-1" label="Telefon" label-for="input-1">
              <b-form-input id="input-1" v-model="telefon" trim></b-form-input>
            </b-form-group>
            <b-form-group id="fieldset-1" label="Hakkında" label-for="input-1">
              <b-form-input id="input-1" v-model="hakkinda" trim></b-form-input>
            </b-form-group>
           </b-card-text>
           <b-button variant="primary" @click="LisansOlustur">Oluştur</b-button>
         </b-card>
      </div>
    </div>
  </Layout>
</template>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import {axiosInstance} from '../../../helpers/axios';
export default {
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Yetkili & Bayi Oluştur",
      items: [{
        text: "Anasayfa"
      },
      {
        text: "Yetkili & Bayi işlemleri",
        active: true
      }
      ],
      email:null,
      password:null,
      role:null,
      adi:null,
      soyadi:null,
      telefon:null,
      hakkinda:null,
      lisanstarihi:null,
      lisansadet:0,
      options: [
          { value:"admin", text: 'Tam yetkili' },
          { value:"basicadmin", text: 'Dist yetkisi' }
        ],
      distoptions:[],
      dist:"",  

    }
  },
  created()
  {
    var vm = this;
    vm.dist_list();
  },
  methods: {
    dist_list()
        {
            var vm = this;
            try {

                axiosInstance.post("dist/list").then((response)=>{

                     if(response.data.error==true)
                        {
                        vm.$bvToast.toast(response.data.message, {
                            title: `Uyari !`,
                            variant: "warning",
                            solid: true
                        });
                        }else{
                            response.data.data.forEach(element => {
                                vm.distoptions.push({
                                  value:element.id,
                                  text:element.adi,
                                })
                            });
                        }

                }).catch((error)=>{

                    vm.$bvToast.toast(error, {
                            title: `Uyari !`,
                            variant: "warning",
                            solid: true
                        });
                    
                })
                
            } catch (error) {

                vm.$bvToast.toast(error, {
                            title: `Uyari !`,
                            variant: "warning",
                            solid: true
                        });
                
            }
      },
    LisansOlustur()
    {
      var vm = this;
        try {

          axiosInstance.post("kullanicilar/yetkili/olustur",{
            email:vm.email,
            password:vm.password,
            role:vm.role,
            adi:vm.adi,
            soyadi:vm.soyadi,
            telefon:vm.telefon,
            hakkinda:vm.hakkinda,
            dist:vm.dist,
            lisansadet:vm.lisansadet,
          }).then((response) => {

          if(response.data.error==true)
                {
                  vm.$bvToast.toast("Hata oluştu.Lütfen daha sonra tekrar deneyiniz ", {
                    title: `Uyari !`,
                    variant: "warning",
                    solid: true
                  });
                }else{
                  vm.$bvToast.toast("Kullanıcı Oluşturulmuştur", {
                    title: `Bildirim !`,
                    variant: "info",
                    solid: true
                  });
                }

          }).catch((error)=>{
            vm.$bvToast.toast("Hata oluştu.Lütfen daha sonra tekrar deneyiniz " + error, {
                  title: `Uyari !`,
                  variant: "warning",
                  solid: true
                });
          })
          
        } catch (error) {
              vm.$bvToast.toast("Hata oluştu.Lütfen daha sonra tekrar deneyiniz " + error, {
                  title: `Uyari !`,
                  variant: "warning",
                  solid: true
                });
        }
    }
  },
}
</script>